import { AxiosPromise } from 'axios'
import { http } from '@/api/httpAxios'

export default class PartnerEducationProgramsAPI {
  public static getUniqueSubjects(): AxiosPromise {
    return http.get('/companies/unique-subjects')
  }

  public static updateSubjects(companyId: number, data: any): AxiosPromise {
    return http.put(`/companies/${companyId}/subjects`, data)
  }

  public static getSubjectsPartner(companyId: number): AxiosPromise {
    return http.get(`/companies/${companyId}/subjects`)
  }
}
