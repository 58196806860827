


















import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SwiperOptions } from 'swiper'
import { imagePreview } from '@/config/defaultImages'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import VueExpandableImage from 'vue-expandable-image'
import 'swiper/css/swiper.css'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component({ components: { VueExpandableImage, Swiper, SwiperSlide } })
export default class GalleryViewList extends Mappers {
  @Prop(Array) readonly gallery!: any[]

  private imagePreview_ = imagePreview

  private swiperComponentOption: SwiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    shortSwipes: true,
    followFinger: true,
    simulateTouch: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  private created() {
    this.swiperComponentOption.slidesPerView = this.isMobile ? 1 : 3
    this.swiperComponentOption.slidesPerGroup = this.isMobile ? 1 : 3
  }

  // TODO kovinevmv call this function here
  private beforeRouteLeave(to: any, from: any, next: any) {
    const image = document.getElementsByClassName('expandable-image')
    if (image && image.length > 0) {
      image[image.length - 1].remove()
    }
    next()
  }
}
