import { AxiosPromise } from 'axios'
import { http } from '@/api/httpAxios'
import createURLParams from '@/api/datasource/createURLParams'
import { saveAs } from 'file-saver'
import { arrayBufferToBlob } from 'blob-util'

export default class PartnerProjectsAPI {
  public static getProjects(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false, true)
    return http.get('/companies/projects', { params })
  }

  public static getActiveProjects(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(
      limit,
      offset,
      sort,
      { ...filter, status: 'active' },
      false,
      true
    )
    return http.get('/companies/projects', { params })
  }

  public static getAllProjects(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false, true)
    return http.get('/companies/projects', { params })
  }

  public static getProject(partnerId: number, projectId: number): AxiosPromise {
    return http.get(`/companies/${partnerId}/projects/${projectId}`)
  }

  public static getProjectParticipation(
    partnerId: number,
    projectId: number
  ): AxiosPromise {
    return http.get(`/companies/${partnerId}/projects/${projectId}/users/check`)
  }

  public static deleteProject(
    companyId: number,
    projectId: number
  ): AxiosPromise {
    return http.delete(`/companies/${companyId}/projects/${projectId}`)
  }

  public static addProject(companyId: number, data: any): AxiosPromise {
    return http.post(`/companies/${companyId}/projects/`, data)
  }

  public static editProject(companyId: number, data: any): AxiosPromise {
    return http.put(`/companies/${companyId}/projects/${data.id}`, data)
  }

  public static deleteFile(
    companyId: number,
    projectId: number,
    projectFileId: number
  ): AxiosPromise {
    return http.delete(
      `/companies/${companyId}/projects/${projectId}/files/${projectFileId}`
    )
  }

  public static uploadFile(
    companyId: number,
    projectId: number,
    file: File
  ): AxiosPromise {
    const filesData = new FormData()
    filesData.append('presentation', file)
    return http.post(
      `/companies/${companyId}/projects/${projectId}/files`,
      filesData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  public static uploadLink(
    companyId: number,
    projectId: number,
    link: any
  ): AxiosPromise {
    return http.post(
      `/companies/${companyId}/projects/${projectId}/links`,
      link
    )
  }

  public static getStudents(
    companyId: number,
    projectId: number,
    limit: number | null,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false, false)
    return http.get(`/companies/${companyId}/projects/${projectId}/users`, {
      params
    })
  }

  public static addStudentOnProject(
    companyId: number,
    projectId: number,
    student: any
  ): AxiosPromise {
    return http.post(
      `/companies/${companyId}/projects/${projectId}/users`,
      student
    )
  }

  public static editStudentOfProject(
    companyId: number,
    projectId: number,
    student: any
  ): AxiosPromise {
    return http.put(
      `/companies/${companyId}/projects/${projectId}/users`,
      student
    )
  }

  public static async exportProjectParticipants(
    companyId: number,
    projectId: number
  ) {
    const response = await http.get(
      `/export/excel/${companyId}/project/${projectId}/participants`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `project-${projectId}.xlsx`)
  }
  public static async exportDocx(companyId: number, projectId: number) {
    const response = await http.get(
      `/export/word/${companyId}/project/${projectId}`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `project-${projectId}.docx`)
  }
  public static async exportZipContracts(projectId: number) {
    const response = await http.get(
      `/export/zip/project/${projectId}/contracts`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `project-${projectId}.zip`)
  }

  public static async exportDocxNew(companyId: number, newsId: number) {
    const response = await http.get(
      `/export/word/${companyId}/news/${newsId}`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `news-${newsId}.docx`)
  }

  public static async exportDocxEvent(companyId: number, eventId: number) {
    const response = await http.get(
      `/export/word/${companyId}/event/${eventId}`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveAs(arrayBufferToBlob(response.data), `event-${eventId}.docx`)
  }

  public static async exportDocxContract(companyId: number) {
    const response = await http.get(`/export/word/${companyId}/contract`, {
      responseType: 'arraybuffer'
    })
    saveAs(arrayBufferToBlob(response.data), `contract-${companyId}.docx`)
  }

  public static deleteStudentFromProject(
    companyId: number,
    projectId: number,
    studentId: number
  ): AxiosPromise {
    return http.delete(
      `/companies/${companyId}/projects/${projectId}/users/${studentId}`
    )
  }

  public static saveResults(
    companyId: number,
    projectId: number,
    results: string
  ): AxiosPromise {
    return http.put(`/companies/${companyId}/projects/${projectId}/results`, {
      results
    })
  }
  public static participateProject(
    companyId: number,
    projectId: number,
    data: any
  ): AxiosPromise {
    return http.put(
      `/companies/${companyId}/projects/${projectId}/users/apply`,
      data
    )
  }

  public static getDepHeads(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, true, true)

    return http.get('/users/depheads', { params })
  }

  public static addProjectManager(
    companyId: number,
    projectId: number,
    userId: number,
    role: string
  ): AxiosPromise {
    return http.post(
      `/companies/${companyId}/projects/${projectId}/managers/${userId}`,
      null,
      { params: { role } }
    )
  }

  public static deleteProjectManager(
    companyId: number,
    projectId: number,
    userId: number
  ): AxiosPromise {
    return http.delete(
      `/companies/${companyId}/projects/${projectId}/managers/${userId}`
    )
  }
}
