


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { SwiperOptions } from 'swiper'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import PartnersAPI from '@/api/partners'
import CarouselCard from '@/components/routes/partner/partner/view/components/CarouselCard.vue'

import 'swiper/css/swiper.css'
import { PracticeCarouselCardAdapter } from '@/components/routes/partner/partner/view/helpers/adapters/practices'

@Component({
  components: { Swiper, SwiperSlide, CarouselCard },
  mixins: [RouterEventWrapper]
})
export default class PracticesViewList extends Vue {
  @Prop(Object) readonly partner!: any

  routerPush!: Function

  private practices: any[] = []

  private swiperComponentOption: SwiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    shortSwipes: true,
    followFinger: true,
    simulateTouch: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }
  private toPartnerPractice() {
    this.routerPush(`/partner/${this.partner.id}/practices`)
  }

  private async created() {
    const response = await PartnersAPI.getPracticesWithTopics(this.partner.id)
    this.practices = PracticeCarouselCardAdapter.serverToClient(
      response.data.rows,
      this.partner.id
    )
  }
}
