import createURLParams from '@/api/datasource/createURLParams'
import { http } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'

export default class VacancyCardsAPI {
  public static getVacancies(
    limit?: number,
    offset?: number,
    sort?: { [k: string]: any } | null,
    filter?: { [k: string]: any } | null
  ) {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get(`/companies/vacancies`, { params })
  }
  public static getVacanciesNonAuth(
    limit?: number,
    offset?: number,
    sort?: { [k: string]: any } | null,
    filter?: { [k: string]: any } | null
  ) {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get(`/companies/vacancies/non-auth`, { params })
  }
  public static getPartnersNonAuth(): AxiosPromise {
    return http.get(`/companies/non-auth`)
  }
}
