import { render, staticRenderFns } from "./PracticesViewList.vue?vue&type=template&id=1c2f12fa&scoped=true&"
import script from "./PracticesViewList.vue?vue&type=script&lang=ts&"
export * from "./PracticesViewList.vue?vue&type=script&lang=ts&"
import style0 from "./PracticesViewList.vue?vue&type=style&index=0&id=1c2f12fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2f12fa",
  null
  
)

export default component.exports