







import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class DescriptionView extends Vue {
  @Prop(String) readonly description!: string
}
