






















import { Vue, Component, Prop } from 'vue-property-decorator'
import NewsPreview from '@/components/routes/news/news-cards/components/NewsPreview.vue'
import PartnersAPI from '@/api/partners'
import { PartnersAdapter } from '@/api/adapters/partners'
import PaginationMixin from '@/mixins/PaginationMixin'

@Component({ components: { NewsPreview }, mixins: [PaginationMixin] })
export default class NewsViewList extends Vue {
  @Prop(Object) readonly partner!: any

  // TODO another call function
  private fetchNext!: Function

  private newsList: any[] = []
  private busy = true

  private async loadMore() {
    this.busy = true
    this.newsList = PartnersAdapter.getNews(
      await this.fetchNext(PartnersAPI.getPartnersNews, this.partner.id, true)
    )
    this.busy = false
  }

  private async onScroll() {
    const scrollHeight = document.documentElement.scrollHeight
    const currentHeight = window.innerHeight + window.scrollY + 20
    if (!this.busy && scrollHeight <= currentHeight) {
      await this.loadMore()
    }
  }

  private async created() {
    window.addEventListener('scroll', this.onScroll)
    await this.loadMore()
  }

  private destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
