























































import { Prop, Vue, Component } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import _ from 'lodash'
import CarouselCard from '@/components/routes/partner/partner/view/components/CarouselCard.vue'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-programs/helpers/requests'
import PartnerEducationSubjectsAPI from '@/components/routes/partner/education-subjects/helpers/requests'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import { routeBuild } from '@/utils/urls'
import { partnerRouteNames, partnerRouteURLs } from '@/router/pages/partner'

@Component({
  components: { CarouselCard },
  mixins: [RouterEventWrapper, ResolveCompanyId]
})
export default class SpecialtiesViewList extends Vue {
  @Prop(Object) readonly partner!: any

  private specialties: any[] = []
  private subjects: any[] = []

  routerPush!: Function
  companyIdToURL!: Function

  private get getURLMore() {
    const url = partnerRouteURLs[partnerRouteNames.PartnerInterestsRoute]
    const partnerId = this.companyIdToURL(this.partner.id)
    return routeBuild(url, { partnerId })
  }

  private toPartnerInterests() {
    this.routerPush(this.getURLMore)
  }

  private get isDisplaySpecialties() {
    return !_.isEmpty(this.specialties)
  }
  private get isDisplaySubjects() {
    return !_.isEmpty(this.subjects)
  }
  private get isDisplayInterests() {
    return !_.isEmpty(this.partner.interests)
  }

  private get isDisplayEmpty() {
    return (
      !this.isDisplaySpecialties &&
      !this.isDisplaySubjects &&
      !this.isDisplayInterests
    )
  }

  private async created() {
    this.specialties = (
      await PartnerEducationProgramsAPI.getSpecialtiesPartner(this.partner.id)
    ).data
    this.subjects = (
      await PartnerEducationSubjectsAPI.getSubjectsPartner(this.partner.id)
    ).data
  }
}
