import createURLParams from '@/api/datasource/createURLParams'
import { http } from '@/api/httpAxios'
import { AxiosPromise } from 'axios'

export default class DialogsAPI {
  // ---- Start Common -----
  public static sendMessage(chatID: number, text: string) {
    return http.post(`/chats/${chatID}/messages`, { text })
  }
  public static readMessages(chatID: number, messageIds: number[]) {
    return http.put(`/chats/${chatID}/messages/read`, { messageIds })
  }
  public static getMessages(
    chatID: number,
    limit: number,
    offset: number,
    anyChat?: boolean
  ) {
    const params = createURLParams(limit, offset)
    if (anyChat) {
      params.append('anyChat', 'true')
    }
    return http.get(`/chats/${chatID}/messages`, { params })
  }
  public static stopChat(chatID: number) {
    return http.put(`/chats/${chatID}/messages/stop`)
  }

  public static checkChatExistence(partnerId: number) {
    const params = new URLSearchParams()
    params.append('scopes', 'minimumData')
    return http.get(`/companies/info/${partnerId}`, { params })
  }
  public static getUnreadMessagesCount() {
    return http.get(`/chats/unread-count`)
  }
  // ---- End Common -----

  // ---- Start Student ------
  public static startDialogWithPartner(partnerID: number): AxiosPromise {
    return http.post(`/chats/companies/${partnerID}`)
  }
  public static getDialogRoomsForStudent(
    limit: number,
    offset: number
  ): AxiosPromise {
    const limitInner = 'no'
    const params = createURLParams(limitInner, offset)
    return http.get('/chats', { params })
  }
  // ---- End Student ------

  // ---- Start Partner ------

  public static startDialogWithStudent(userId: number | string) {
    return http.post(`/chats/chat/${userId}`)
  }

  public static getProjectChats(projectId: string | number) {
    return http.get(`/chats/projects/${projectId}`)
  }

  public static getDialogRoomsForPartner(
    limit: number,
    offset: number
  ): AxiosPromise {
    const limitInner = 'no'
    const params = createURLParams(limitInner, offset)
    return http.get('/chats', { params })
  }

  public static banUser(userId: number, banReason: string, chatId: number) {
    return http.post(`/claims/create`, {
      studentId: userId,
      info: banReason,
      chatId
    })
  }
  // ---- End Partner ------
}
