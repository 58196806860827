







































import { Component, Prop, Vue } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'

@Component({ mixins: [RouterEventWrapper] })
export default class CarouselCard extends Vue {
  @Prop(Number) totalCount!: number
  @Prop(Array) data!: any[]
  @Prop(String) title!: string
  @Prop(String) nameFieldName!: string
  @Prop(String) sourceFieldName?: string
  @Prop(String) tooltipFieldName?: string
  @Prop(String) linkFieldName?: string
  @Prop(String) urlMore?: string
  @Prop({ type: Number, default: 3 }) readonly maxCountToShowElements!: number

  private isOverflow = false
  private elementsToShow: any[] = []

  created() {
    this.isOverflow =
      (this.totalCount ? this.totalCount : this.data.length) >
      this.maxCountToShowElements
    this.elementsToShow = this.data.slice(0, this.maxCountToShowElements)
  }
}
