export enum statusTypeEventServer {
  publicated = 'publicated',
  draft = 'draft',
  await = 'await',
  denied = 'denied'
}

export const statusTypeEventRu: { [k: string]: string } = {
  [statusTypeEventServer.publicated]: 'Опубликовано',
  [statusTypeEventServer.draft]: 'Черновик',
  [statusTypeEventServer.await]: 'На согласовании',
  [statusTypeEventServer.denied]: 'Отказано'
}

export const translateStatusType: { [k: string]: string } = {
  [statusTypeEventServer.publicated]: statusTypeEventRu.publicated,
  [statusTypeEventServer.draft]: statusTypeEventRu.draft,
  [statusTypeEventServer.await]: statusTypeEventRu.await,
  [statusTypeEventServer.denied]: statusTypeEventRu.denied
}
