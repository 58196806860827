export class PracticeCarouselCardAdapter {
  public static serverToClient(data: any[], partnerId: number) {
    for (const practice of data) {
      for (const topic of practice.practiceTopics) {
        topic.url = `/partner/${partnerId}/practices/${practice.id}/theme/${topic.id}`
      }
    }
    return data
  }
}
