import { render, staticRenderFns } from "./NewsViewList.vue?vue&type=template&id=20cbc896&scoped=true&"
import script from "./NewsViewList.vue?vue&type=script&lang=ts&"
export * from "./NewsViewList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cbc896",
  null
  
)

export default component.exports