

























import { Component, Prop, Vue } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import CarouselCard from '@/components/routes/partner/partner/view/components/CarouselCard.vue'
import { vacancyStatus } from '@/config/vacancies/vacancies'
import { VacancyCarouselCardAdapter } from '@/components/routes/partner/partner/view/helpers/adapters/vacancies'
import { userMappers } from '@/store/modules/user'
import VacancyCardsAPI from '@/components/routes/vacancies/vacancy-cards/helpers/requests'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['isAuthenticated'])
  }
})

@Component({ components: { CarouselCard }, mixins: [RouterEventWrapper] })
export default class VacanciesViewList extends Mappers {
  @Prop(Object) readonly partner!: any

  routerPush!: Function

  private vacancies: any[] = []
  private totalCount = 0

  private toPartnerVacancies() {
    this.routerPush(this.moreURL)
  }

  private get moreURL() {
    return `/vacancies?filter={"companyId":[${this.partner.id}]}`
  }

  private async created() {
    const func = this.isAuthenticated
      ? VacancyCardsAPI.getVacancies
      : VacancyCardsAPI.getVacanciesNonAuth
    const response = await func(5, 0, null, {
      companyId: [this.partner.id],
      status: { type: 'enum', filter: [vacancyStatus.publicated] }
    })
    this.vacancies = VacancyCarouselCardAdapter.serverToClient(
      response.data.rows,
      this.partner.id
    )
    this.totalCount = response.data.count
  }
}
