













import { Component, Vue } from 'vue-property-decorator'
import { navBarTitle } from '@/store/modules/navbar'

const Mappers = Vue.extend({
  computed: {
    ...navBarTitle.mapState(['totalCountFloatButton'])
  }
})

@Component
export default class ScrollTop extends Mappers {
  private showButtonTop = false

  private get styleArrowUp() {
    return !this.totalCountFloatButton
      ? 'over button-top-block'
      : 'over button-top-block-with-float'
  }

  private created() {
    window.addEventListener('scroll', this.handleScroll)
  }

  private goToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  private handleScroll() {
    this.showButtonTop = window.pageYOffset > 0
  }
}
