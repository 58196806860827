


































import { Component, Prop, Vue } from 'vue-property-decorator'
import LikesEditor from '@/components/ui/LikesEditor.vue'
import { imagePreview } from '@/config/defaultImages'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import { newsRouteNames, newsRouteURLs } from '@/router/pages/news'
import { buildURL } from '@/utils/urls'

@Component({
  components: { LikesEditor },
  mixins: [RouterEventWrapper]
})
export default class NewsPreview extends Vue {
  @Prop(Object) news: any
  routerPush!: Function

  private imagePreview_ = imagePreview

  $refs!: {
    previewImage: HTMLImageElement
  }

  private previewHeight = 155

  onPreviewLoad(event: any) {
    this.previewHeight = event.target.clientWidth * 0.5625 // 0.5625 = 9/16 => Соотношение сторон: 16:9
  }

  private hover = false
  onHover(hovered: boolean) {
    this.hover = hovered
  }

  onPreviewClick() {
    const url = newsRouteURLs[newsRouteNames.NewsViewRoute]
    this.routerPush(
      buildURL(url, {
        partnerId: this.news.companyId,
        newsId: this.news.id
      })
    )
  }
}
