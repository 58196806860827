



































































































import { Component, Vue } from 'vue-property-decorator'
import { userMappers } from '@/store/modules/user'
import NewsPreview from '@/components/routes/news/news-cards/components/NewsPreview.vue'
import PartnersAPI from '@/api/partners'
import { PartnersAdapter } from '@/api/adapters/partners'
import Loading from '@/components/ui/Loading.vue'
import DialogsAPI from '@/api/dialogs'

import VueExpandableImage from 'vue-expandable-image'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import { partnerMapper } from '@/store/modules/partner'
import ViewContacts from '@/components/routes/partner/partner/view/components/ViewContacts.vue'
import CommonPartnerPermissions, {
  permissionsPartner
} from '@/api/permissions/CommonPartnerPermissions'
import ScrollTop from '@/components/routes/common/main-page/components/ScrollTop.vue'
import CommonInformationProfile from '@/components/routes/partner/partner/edit/components/CommonInformationProfile.vue'
import PresentationsViewList from '@/components/routes/partner/partner/view/components/PresentationsViewList.vue'
import _ from 'lodash'
import GalleryViewList from '@/components/routes/partner/partner/view/components/GalleryViewList.vue'
import DescriptionView from '@/components/routes/partner/partner/view/components/DescriptionView.vue'
import SpecialtiesViewList from '@/components/routes/partner/partner/view/components/SpecialtiesViewList.vue'
import PracticesViewList from '@/components/routes/partner/partner/view/components/PracticesViewList.vue'
import VacanciesViewList from '@/components/routes/partner/partner/view/components/VacanciesViewList.vue'
import ProjectsViewList from '@/components/routes/partner/partner/view/components/ProjectsViewList.vue'
import EventsViewList from '@/components/routes/partner/partner/view/components/EventsViewList.vue'
import NewsViewList from '@/components/routes/partner/partner/view/components/NewsViewList.vue'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import { partnerRouteNames, partnerRouteURLs } from '@/router/pages/partner'
import { routeBuild } from '@/utils/urls'
import ProjectSetAdditionalFields from '@/components/routes/projects/project-page/components/project-members/components/ProjectSetAdditionalFields.vue'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters([
      'isAuthenticated',
      'isPartner',
      'isStudent',
      'isPRManager',
      'userCompany',
      'isBannedUser'
    ]),
    ...partnerMapper.mapState(['events', 'idPartner'])
  }
})

@Component({
  components: {
    ProjectSetAdditionalFields,
    CommonInformationProfile,
    ViewContacts,
    Loading,
    EventsViewList,
    VacanciesViewList,
    NewsPreview,
    DescriptionView,
    ProjectsViewList,
    GalleryViewList,
    NewsViewList,
    ScrollTop,
    PracticesViewList,
    VueExpandableImage,
    PresentationsViewList,
    SpecialtiesViewList
  },
  mixins: [RouterEventWrapper, ResolveCompanyId]
})
export default class PartnerView extends Mappers {
  routerPush!: Function
  getCompanyId!: Function
  companyIdToURL!: Function

  partner: any = {
    info: '',
    interests: [],
    listFieldActivity: '',
    listStaffCount: ''
  }

  private isBanned = false
  private additionalFields: any = {}

  partnerId = 0
  canEditPartnerPage = false
  isLoading = false

  private async created() {
    this.isLoading = true
    this.partnerId = this.getCompanyId(true)
    this.canEditPartnerPage =
      (this.isPartner && this.userCompany.company.id === this.partnerId) ||
      this.isPRManager

    this.partner = PartnersAdapter.getPartner(
      (await PartnersAPI.getOnePartner(this.partnerId)).data
    )

    if (this.isPartner || this.isPRManager) {
      const responseAdditionalFields = await PartnersAPI.getAdditionalTemplateFields(
        this.partnerId
      )
      this.additionalFields = responseAdditionalFields.data
    }

    this.isBanned = this.isBannedUser

    this.isLoading = false
  }

  private async startDialog() {
    if (this.partner) {
      if (!this.partner.userChat) {
        const response = await DialogsAPI.startDialogWithPartner(
          this.partner.id
        )
        this.routerPush(`/dialogs/user?chat=${response.data.id}`)
      } else {
        this.routerPush(`/dialogs/user?chat=${this.partner.userChat.id}`)
      }
    }
  }

  private goToEditPage() {
    const url = partnerRouteURLs[partnerRouteNames.PartnerEditRoute]
    const partnerId = this.companyIdToURL(this.partnerId)
    this.routerPush(routeBuild(url, { partnerId }))
  }

  private onWord() {
    if (_.isEmpty(this.additionalFields)) {
      this.$bvModal.show('projectSetAdditionalFields')
    } else {
      this.onExportWord()
    }
  }

  private async onExportWord() {
    await PartnersAPI.exportWordStudentPractice(this.partnerId)
  }

  private async viewContacts() {
    ;(this.$refs as any).contactsModal.setCompany(this.partnerId)
    this.$bvModal.show('contactsModalId')
  }
  private get canChat() {
    const perms = CommonPartnerPermissions.common(this.partner)
    return perms.can(permissionsPartner.chats, undefined)
  }

  private isDisplayInfo() {
    return !_.isEmpty(this.partner.info)
  }

  private get isDisplayPresentations() {
    return !_.isEmpty(this.partner.presentation)
  }

  private get isDisplayGallery() {
    return !_.isEmpty(this.partner.gallery)
  }

  private isDisplayContent() {
    return this.isAuthenticated
  }

  private get isNotBanned() {
    return !this.partner.bannedAt
  }
  private get isDisplaySpecialities() {
    return true
  }
  private get isDisplayPractices() {
    return this.isNotBanned && this.isDisplayContent()
  }

  private get isDisplayVacancies() {
    return this.isNotBanned
  }
  private get isDisplayProjects() {
    return this.isNotBanned
  }
  private get isDisplayEvents() {
    return this.isNotBanned && this.isDisplayContent()
  }
  private get isDisplayNews() {
    return this.isNotBanned && this.isDisplayContent()
  }
}
