


































import { Vue, Component, Prop } from 'vue-property-decorator'
import PartnersAPI from '@/api/partners'

@Component
export default class ViewContacts extends Vue {
  @Prop(String) readonly modalId!: string

  private users: any[] = []

  public async setCompany(companyId: number) {
    this.users = (await PartnersAPI.getContacts(companyId)).data
  }
}
