

























import { Component, Prop, Vue } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import CarouselCard from '@/components/routes/partner/partner/view/components/CarouselCard.vue'
import PartnerProjectsAPI from '@/api/partnerProject'
import { ProjectsCarouselCardAdapter } from '@/components/routes/partner/partner/view/helpers/adapters/projects'

@Component({ components: { CarouselCard }, mixins: [RouterEventWrapper] })
export default class ProjectsViewList extends Vue {
  @Prop(Object) readonly partner!: any

  routerPush!: Function

  private projects: any[] = []
  private totalCount = 0

  private toPartnerProject() {
    this.routerPush(this.moreURL)
  }

  private get moreURL() {
    const params = new URLSearchParams()
    params.append('filter', JSON.stringify({ partnerId: [this.partner.id] }))
    return `/projects?${params.toString()}`
  }

  private async created() {
    const response = await PartnerProjectsAPI.getProjects(7, 0, null, {
      companyId: this.partner.id,
      status: 'active'
    })
    this.projects = ProjectsCarouselCardAdapter.serverToClient(
      response.data.rows,
      this.partner.id
    )
    this.totalCount = response.data.count
  }
}
