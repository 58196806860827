














import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PresentationsViewList extends Vue {
  @Prop(Array) readonly presentations!: any[]
}
