




























import { Vue, Component, Prop } from 'vue-property-decorator'
import SectionDocumentSettings from '@/components/routes/partner/partner/edit/components/SectionDocumentSettings.vue'

@Component({ components: { SectionDocumentSettings } })
export default class ProjectSetAdditionalFields extends Vue {
  @Prop(Object) readonly data!: any
  @Prop(String) readonly modalId!: string
  @Prop(Number) readonly partnerId!: number

  private isDisabledContinue = true

  private onSaveData() {
    this.isDisabledContinue = false
  }
  private onOk() {
    this.$emit('save')
  }
}
