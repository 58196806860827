




import { Vue, Component } from 'vue-property-decorator'
import PartnerView from '@/components/routes/partner/partner/view/index.vue'

@Component({ components: { PartnerView } })
export default class PartnerViewRoute extends Vue {}
