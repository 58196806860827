

























import { Component, Prop, Vue } from 'vue-property-decorator'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import CarouselCard from '@/components/routes/partner/partner/view/components/CarouselCard.vue'
import PartnersAPI from '@/api/partners'
import { statusTypeEventServer } from '@/config/event/status'
import { EventsCarouselCardAdapter } from '@/components/routes/partner/partner/view/helpers/adapters/events'

@Component({ components: { CarouselCard }, mixins: [RouterEventWrapper] })
export default class EventsViewList extends Vue {
  @Prop(Object) readonly partner!: any

  routerPush!: Function

  private events: any[] = []
  private totalCount = 0

  private toPartnerEvents() {
    this.routerPush(this.moreURL)
  }

  private get moreURL() {
    const params = JSON.stringify({ companyId: this.partner.title })
    return `/events?filter=${params}`
  }

  private async created() {
    const response = await PartnersAPI.getEventsPartner(
      this.partner.id,
      4,
      0,
      null,
      {
        status: statusTypeEventServer.publicated
      }
    )
    this.events = EventsCarouselCardAdapter.serverToClient(response.data.rows)
    this.totalCount = response.data.count
  }
}
